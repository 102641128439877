












































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import { mdiNumeric1Circle, mdiNumeric2Circle } from "@mdi/js";
@Component({
    components: { BackButton },
})
export default class FaqCanNyangaPayAutomaticallyWithdrawMoneyFormMyMobileMoneyAccount extends Vue {
    oneIcon: string = mdiNumeric1Circle;
    twoIcon: string = mdiNumeric2Circle;
}
